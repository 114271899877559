<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import Swal from "sweetalert2";
    import axios from "axios";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Tambah Jenis Perizinan",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Tambah Jenis Perizinan",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Jenis Perizinan",
                        href: "/master/perizinan-jenis",
                    },
                    {
                        text: "Tambah Jenis Perizinan",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,
                // variable Page
                
                mpj_nama: null,
            };
        },
        mounted() {},
        methods:{
            // Method dari masing masing fungsi di dalam file ini
            StoreData() {
                let self = this;
                Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
                axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-jenis", {
                        mpj_nama: self.mpj_nama,
                    },
                    {headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    }
                })
                .then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master jenis perizinan segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-jenis-perizinan" });
                        }
                    });
                }).catch(e => {
                    this.axiosCatchError = e.response.data.data
                    Swal.close();
                })
			},
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                {{axiosCatchError}}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0]}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Nama Jenis Perizinan" label-for="formrow-nama-perizinan-jenis-input">
                                        <b-form-input id="formrow-nama-perizinan-jenis-input" type="text" v-model="mpj_nama"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
